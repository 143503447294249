body {
    background-color: #161616;
    color: #ecdbba;
    font-family: "Fira Code", monospace;
    font-size: x-large;
}

.navbar-link {
    display: inline-block;
    position: relative;
    color: #c84b31;
    text-decoration: none;
}

.navbar-link::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #c84b31;
    transform-origin: bottom right;
    transition: transform 0.1s ease-out;
}

.navbar-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.header {
    height: 10%;
    color: #c84b31;
}

.content {
    height: 80%;
    margin-left: 3em;
    margin-right: 3em;
    align-self: center;
    padding-top: 2em;
    padding-left: 15%;
    padding-right: 15%;
}

.footer {
    display: none; /*TO DO: FIGURE OUT HOW TO PIN THIS TO THE BOTTOM ALL THE TIME */
    height: 10%;
    align-self: center;
    position: absolute;
    bottom: 0;
    color: #346751;
    width: 100%;
    height: 2.5rem;
}

.info-title {
    text-decoration: underline;
    color: #e8c98a;
}

li {
    margin-bottom: 1em;
}

img {
    width: 7em;
    height: 7em;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #346751;
}

.active {
    color: #48be8b;
}

.other-links {
    text-decoration: underline;
    color: #ecdbba;
}

/* ========= MOBILE STYLE ========= */
@media only screen and (max-width: 1023px) {
    body {
        font-size: medium;
    }
    .content {
        height: 80%;
        margin-left: 0.5em;
        margin-right: 0.5em;
        align-self: center;
        padding-top: 1em;
        padding-left: 2vw;
        padding-right: 2vw;
        font-size: large;
    }

    ul {
        align-items: center;
    }
}
